import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import {
  Heading, Cover, Panel, Container, Alert, Text,
} from '../../elements';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';
import Back from '../../components/Back';
import collage from '../../images/covers-collage_alt.jpg';
import AppContainer from '../../components/AppContainer';
import { Config } from '../../queries';

const CoverContain = styled(Cover)`
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
`;

const Iframe = styled.iframe`
  min-height: 650px;
  width: 100%;
  margin: -10px;
`;

const Upload = ({ data: { contentfulPage: page } }) => (
  <AppContainer pageTitle={page.title}>
    <Container id="content">
      <Panel>
        <Back to="/" />
        <Heading ariaRole="heading">{page.heading}</Heading>
        <div>
          <h2>{page.subHeading}</h2>
          <Alert>
            <span dangerouslySetInnerHTML={{ __html: page.content.childContentfulRichText.html }} />
          </Alert>
          <Config>
            {config => (
              <Iframe
                title="ajn-file-upload"
                src={config.data[0].sharefileIframeUrl}
                frameBorder="0"
                scrolling="auto"
                id="sfRemoteUploadFrame"
              />
            )}
          </Config>
        </div>
      </Panel>
      <PreviewCompatibleImage imageInfo={page.image} />
    </Container>
  </AppContainer>
);

export const uploadPageQuery = graphql`
  query UploadPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      heading
      subHeading
      image {
        fluid(maxWidth: 1000, quality: 70) {
          ...GatsbyContentfulFluid
        }
      }
      content {
        childContentfulRichText {
          html
        }
      }
    }
  }
`;

export default Upload;
